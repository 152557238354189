import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO />
    <h1>Hello!</h1>
    <p>
      I'm Eric. At work, I mostly do full-stack web development, but I also have
      experience in data science and product management.
    </p>
    <p>
      I like learning about and working on ideas in parenting, education,
      finance, markets, clean tech, and niche solutions for overlooked
      industries.
    </p>
    <p>
      You can reach me at <a href="mailto:eric@mckay.casa">eric@mckay.casa</a>{' '}
      or by DM on Twitter{' '}
      <a href="https://twitter.com/eric__mckay">@eric__mckay</a>.
    </p>
  </Layout>
)

export default IndexPage
